<template>
  <v-app>
    <amplify-authenticator username-alias="email" :initial-auth-state="authScreen" >
      <amplify-sign-in
        slot="sign-in"
        username-alias="email"
      >
      </amplify-sign-in>

      <amplify-sign-up
        slot="sign-up"
        username-alias="email"
        :form-fields.prop="formFields"
      ></amplify-sign-up>
    </amplify-authenticator>
    <div v-if="signedIn">
      <v-navigation-drawer 
        app
        v-model="drawer"
        fixed
        temporary
      >
        <template v-slot:prepend>
          <v-list-item two-line>
            <v-list-item-avatar>
              <!-- <img src="https://randomuser.me/api/portraits/women/81.jpg"> -->
              <v-icon class="text-h3">mdi-account-circle</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>Bem vindo!</v-list-item-title>
              <v-list-item-subtitle>{{userMail}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-divider></v-divider>

        <v-list
          nav
          dense
        >
          <v-list-item-group
          
          >
            <v-list-item to="/p/titulos">
              <v-list-item-icon>
                <v-icon>mdi-cash-multiple</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Títulos Disponíveis</v-list-item-title>
            </v-list-item>

            <v-list-item to="/p/alertas">
              <v-list-item-icon>
                <v-icon>mdi-bell-ring-outline</v-icon> 
              </v-list-item-icon>
              <v-list-item-title>Alertas</v-list-item-title>
            </v-list-item>
            <v-list-item to="/p/doacao">
              <v-list-item-icon>
                <v-icon>mdi-hand-coin-outline</v-icon> 
              </v-list-item-icon>
              <v-list-item-title>Contribua</v-list-item-title>
            </v-list-item>
            <v-list-item to="/p/config">
              <v-list-item-icon>
                <v-icon>mdi-cog-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Configurações</v-list-item-title>
            </v-list-item>
            <v-list-item to="/p/ajuda">
              <v-list-item-icon>
                <v-icon>mdi-help-circle-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Ajuda</v-list-item-title>
            </v-list-item>
            <v-list-item @click="sair()">
              <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Sair</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>

      <v-app-bar app
        color="primary"
        dark
      >
        <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>  
        <v-toolbar-title>{{ pageTitle }}</v-toolbar-title>
        
      </v-app-bar>
    
      <v-main>
        <router-view/>
      </v-main>
    </div>
    <v-snackbar
      top
      v-model="snackbar"
    >
      {{snackbarText}}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          color="red"
          v-bind="attrs"
          @click="snackbar = false"
        >
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import { Hub, Auth } from 'aws-amplify';
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";

export default {
  name: 'Main',
  components: {
    
  },
  data: () => ({
    drawer: false,
    userMail: '',
    snackbar : false,
    signedIn : false,
    snackbarText: "",
    unsubscribeAuth: undefined,
    formFields: [
        { type: 'email', required: true },
        { type: 'password', required: true }
      ]
  }),
  created() {
    this.unsubscribeAuth = onAuthUIStateChange((authState, user) => {
        this.signedIn = false;
        if (authState === AuthState.SignedIn) {
          this.userMail = user.attributes["email"];
          this.$store.state.user = user;
          this.signedIn = true;
        }
    }) 
  },
  beforeDestroy() {
    //this.unsubscribeAuth();
  },
  mounted() {
    Hub.listen("UI Auth", (res) => {  //Listen to UI Auth channel
      if (res?.payload?.event === 'ToastAuthError') {
        let errorMsg = res?.payload?.message
          ? res.payload.message
          : null;

        //console.info(errorMsg);
        // Hide Amplify's default Toast since we're showing our own
        const target = document.getElementsByTagName("amplify-authenticator")[0];
        if (target?.shadowRoot?.children) {
          let interval = setInterval(function () {
            let el = target.shadowRoot.children[0];
            if (el.tagName.toLocaleLowerCase() === "amplify-toast") {
              el.style.display = "none";
              clearInterval(interval);
            }
          }, 1);  // delay time = 1ms
        }
        
        this.snackbar = true;
        this.snackbarText = errorMsg;
      }
    });
  },
  methods: {
    sair() {
      Auth.signOut().then(this.$router.push('/'));
    }
  },
  computed: {
    pageTitle() {
      return this.$store.state.pageTitle
    },
    authScreen() {
      if (this.$route.query.authScreen) {
        return this.$route.query.authScreen;
      }
      return "signin";
    }
  }
};
</script>

<style>

amplify-authenticator {
  --amplify-primary-color: #1976d2;
  --amplify-primary-tint: #42A5F5;
  --amplify-primary-shade:#0D47A1;
}

</style>