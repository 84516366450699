<template>
  <div id="topo" class="ma-2">
    <v-row justify="center" v-if="!loading">
      <v-col sm="8" md="6" lg="6">
        <v-simple-table v-if="titulos.length">
            <template v-slot:default>
              <thead>
                  <tr>
                      <th class="text-left">
                          Nome
                      </th>
                      <th class="text-left">
                          Taxa
                      </th>
                  </tr>
              </thead>
              <tbody>
                  <tr :class="getClassByNomeTitulo(titulo.nome)" v-for="titulo in titulos" :key="titulo.id">
                      <td width="80%" class="texto-titulo"><span>{{ titulo.nome }}</span></td>
                      <td width="20%" class="texto-taxa">{{ titulo.taxa.toFixed(2) }}%</td>
                  </tr>
              </tbody>
            </template>
        </v-simple-table>
        <v-alert v-if="!titulos.length"
          border="top"
          colored-border
          type="info"
          elevation="2"
        >
          Não foi possível buscar a lista de títulos disponíveis no <a href="https://www.tesourodireto.com.br/titulos/precos-e-taxas.htm" target="_blank">site do Tesouro Direto</a>. Tente novamente mais tarde.
        </v-alert>
      </v-col>
    </v-row>
    <!-- <v-row v-if="loading" class="text-center" justify="center">
      <v-col>
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-col>
    </v-row> -->
    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<style scoped>
.texto-titulo {
  border-top: 4px solid transparent;
  border-left: 4px solid;  
  white-space: pre-line;
}

.texto-taxa {
  font-weight: bold;
}

.texto-ipca {
  color: #ce185d;
}

.texto-prefixado {
  color: #1175b7;
}

.texto-renda {
  color: #a253a5;
}

.texto-educa {
  color: #243368;
}

</style>

<script>
import axios from 'axios'

export default {
  data: function() {
      return {
        loading: false,
        resourceUrl: process.env.VUE_APP_BASE_API_URL+"/api/v1/titulos",
        titulos: []
      }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    jwt() {
      return this.$store.state.user
          .getSignInUserSession()
          .getIdToken()
          .getJwtToken();
    },
    headers() {
      return { headers : { authorization : this.jwt } };
    }
  },
  methods: {
    load() {
      if (this.user) {
        this.loading = true;
        this.$vuetify.goTo('#topo', { duration: 0 });
        axios
          .get(this.resourceUrl, this.headers )
          .then(response => {
            this.titulos = response.data.map(titulo => ({
              ...titulo, // Mantém os outros atributos
              nome: titulo.nome.replace("com", "\ncom")
                               .toUpperCase()
                               .replace("APOSENTADORIA EXTRA", "\naposentadoria extra")
                               .replace("COM JUROS SEMESTRAIS", "com juros semestrais")
                
            }));

            let prefixados = this.titulos.filter(titulo => titulo.nome.includes("PREFIXADO"));
            let ipca = this.titulos.filter(titulo => titulo.nome.includes("IPCA"));
            let renda = this.titulos.filter(titulo => titulo.nome.includes("RENDA"));
            let educa = this.titulos.filter(titulo => titulo.nome.includes("EDUCA"));

            this.titulos = [...prefixados, ...ipca, ...renda, ...educa]

            this.loading = false;
          })
          .catch(error => {
            console.info(error);
            this.$router.push('/');
          });
      }
    },
    getClassByNomeTitulo(nome) {
      return {
          "texto-ipca" : nome.toUpperCase().includes('IPCA'),
          "texto-prefixado" : nome.toUpperCase().includes('PREFIXADO'),
          "texto-educa" : nome.toUpperCase().includes('EDUCA'),
          "texto-renda" : nome.toUpperCase().includes('RENDA'),
      }
    }
  },
  created() {
    //document.title = "Títulos Disponíveis";
  },
  mounted() {
    this.$store.state.pageTitle = 'Títulos Disponíveis';
    this.load();
  },
  watch: {
    user(){
      if (this.user) {
        this.load();
      }
    }
  }
}
</script>